/* eslint-disable indent */
import React from "react"
import DesktopFooter from "./desktop/DesktopFooter"
import MobileFooter from "./mobile/MobileFooter"

const Footer = () => {
  return (
    <>
      <div className="d-block d-md-none">
        <MobileFooter/>
      </div>
      <div className="d-none d-md-block">
        <DesktopFooter/>
      </div>
    </>
  )
}

export default Footer

