import React from "react"
import FooterForm from "../FooterForm"
import FooterLabel from "../FooterLabel"
import FooterText from "../FooterText"
import FooterContentMobile from "./FooterContentMobile"

const MobileFooter = () => {
	return (
		<footer className="text-white" style={{background: "#212529", borderRadius: "24px 24px 0 0"}}>
			<section className="container">
				<div className="row justify-content-betwen align-items-center pt-5">
					<FooterText centered={false}/>
					<FooterForm/>
					<FooterContentMobile/>
				</div>
				<FooterLabel/>
			</section>
		</footer>
	)
}

export default MobileFooter
