import React from "react"
import PropTypes from "prop-types"
import {useTranslation} from "next-i18next"
import Typography from "../reusable/Typography/Typography"
import Link from "next/link"

type Props = {
  centered?: boolean
}

const FooterText: React.FC<Props> = ({centered = false}) => {
	const {t} = useTranslation()
	return (
		<div className={`${centered ? "text-center" : ""}`}>
			<Typography className="mb-2 mb-md-3 d-md-none" variant="bodyLgBlack" semanticTag="h2" style={{fontSize: 20, lineHeight: "32px"}}>Du bist noch nicht bereit Deinen MyHomePlan zu starten?</Typography>
			<Typography className="mb-2 mb-md-3 d-none d-md-block" variant="bodyLgBlack" semanticTag="h2" style={{fontSize: 36, lineHeight: "32px"}}>MyBikePlan AG</Typography>
			<div className="d-none d-md-block">
				<Link href={"tel:+41%2043%20505%2013%2018"}>
					<a className="text-decoration-none text-white">
						<Typography className="mb-2" variant="bodySm">{`${t("consultation")}: +41 43 505 13 18`}</Typography>
					</a>
				</Link>

				<Link href={"mailto:hello@myhomeplan.ch"}>
					<a className="text-decoration-none text-white">
						<Typography variant="bodySm">hello@myhomeplan.ch</Typography>
					</a>
				</Link>
			</div>

		</div>
	)
}

FooterText.propTypes = {
	centered: PropTypes.bool
}
export default FooterText
