import React from "react"
import FooterContent from "./FooterContentDesktop"
import FooterForm from "../FooterForm"
import FooterLabel from "../FooterLabel"

const DesktopFooter = () => {
	return (
		<footer className="text-white d-none d-md-block" style={{background: "#212529", borderRadius: "24px 24px 0 0"}}>
			<section className="container">
				<div className="row align-items-top mb-5 gx-5">
					<FooterContent/>
					<FooterForm/>
				</div>
				<FooterLabel/>
			</section>
		</footer>
	)
}

export default DesktopFooter
