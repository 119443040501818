import React from "react"
import PropTypes from "prop-types"
import GoogleReviewsItem from "../GoogleReviewsItem"
import {useRouter} from "next/router"
import {Slides} from "../../reusable/components/Slides"

type Props = {
  reviews: any[]
}

const ReviewSlider: React.FC<Props> = ({reviews}) => {
	const router = useRouter()
	const {locale} = router
	const reviewsList = reviews.filter(review => review.lang === locale && typeof review !== "undefined" && typeof review.comment !== "undefined" && review.starRating === "FIVE")
	const reviewsListShortMbl = reviewsList.slice(0, 6)

	return (
		<>

			<div className="review-slider d-block d-md-none">
				<Slides arrows={false} arrowsPositionX={-4} dots={true} dotsPositionY={-24}
					slidesToShow={1}
					className="col-12 position-relative">
					{reviewsListShortMbl?.map(rev => {
						return (
							<GoogleReviewsItem transparent={true} className="col-md-12 px-md-3" review={rev}
								key={rev.reviewId}/>)
					})}
				</Slides>
			</div>

			<div className="d-none d-md-block review-slider">
				<Slides arrows arrowsPositionX={-4} dotsPositionY={-60} dots slidesToShow={4} className="col-12 position-relative">
					{reviewsListShortMbl?.map(rev => {
						return (
							<GoogleReviewsItem className="col-md-12 px-md-3" review={rev} key={rev.reviewId}/>
						)
					})}
				</Slides>
			</div>

		</>
	)
}

ReviewSlider.propTypes = {
	reviews: PropTypes.array.isRequired
}

export default ReviewSlider
