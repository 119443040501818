import React from "react"
import PropTypes from "prop-types"
import Link from "next/link"
import parse from "html-react-parser"
import {useTranslation} from "next-i18next"
import Typography from "../../reusable/Typography/Typography"
import LinkWithIcon from "../../reusable/components/LinkWithIcon"
import {useRouter} from "next/router"

type Props = {
  post: any,
  height: number,
  width?: any,
  isSliderItem?: boolean,
}

const BlogsCollectionItem: React.FC<Props> = ({post, height, isSliderItem = false, width}) => {
	// eslint-disable-next-line camelcase
	const {title, slug, excerpt, featured_image} = post
	const {t} = useTranslation()
	const router = useRouter()

	return (
		<div className={"blog-item h-100 border-0 "}
			style={{
				backgroundColor: "#F8F8F8",
				borderRadius: 32,
				border: "1px solid red",
				height
			}}>
			<div
				className={"pb-3 align-items-top"}
				style={{
					padding: "16px 16px 32px"
				}}>
				<div className=" justify-content-center"
					style={{height: 205, width: "100%"}}>
					<Link href={`${t("/blog")}/${post.slug}`}>
						<a style={{width: "100%", borderRadius: 16}}>
							<div style={{
								height: 205,
								borderRadius: 16,
								// eslint-disable-next-line camelcase
								backgroundImage: `url(${featured_image || "/assets/images/blog/landscape.jpg"})`,
								backgroundSize: "cover",
								backgroundPosition: "center"
							}}>
							</div>
						</a>
					</Link>
				</div>
				<div className="col-12 px-2 col-md-10 px-md-1">
					<div className="row mt-4">
						<Typography variant="bodyLgBold" semanticTag="h2" className="col-12" style={{minHeight: 75}}>
							{parse(title.rendered)}
						</Typography>
						<div className="mt-4 ps-1">
							<LinkWithIcon href={`${t("/blog")}/${post.slug}`} label={t("Mehr lesen")}/>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

BlogsCollectionItem.propTypes = {
	post: PropTypes.any.isRequired,
	height: PropTypes.any.isRequired
}

export default BlogsCollectionItem
