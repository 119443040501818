import React from "react"
import FormNewsletter from "../forms/FormNewsletter"

const FooterForm = () => {
	return (
		<FormNewsletter footer/>
	)
}

export default FooterForm
